import { useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { getConsentLink, getTermsLink } from '../../../utils/FindContext';
import { getCountryList } from '../../../utils/country';
import {
  composeValidators,
  firstNameLength,
  lastNameLength,
  mustAcceptTermsAndConditions,
  requiredWithLabel,
  trimValue,
  validateEmail,
  validMobileNumber,
} from '../../../utils/ValidationsUtils';
import AccessibleH1Heading from '../../components/AccessibleH1Heading';
import ErrorInfo from '../../components/ErrorInfo';
import FormErrorSummary, { focusAfterTimeout } from '../../components/FormErrorSummary';
import InputType from '../../components/InputType';
import PhoneInputType from '../../components/PhoneInputType';
import VippsButton from '../../components/VippsButton/VippsButton';
import { createSmsCode, validateUserDetails } from './api';
import SetPasswordInputField, { validatePassword } from '../../components/PasswordInput/SetPasswordInputField';
import CheckboxInputType from '../../components/CheckboxInputType';
import registerUserAndLoginAction from './registerUserAndLoginAction';
import type { ENTER_USER_DETAILS, FromPasswordReset, SetStepFunction, UserDetails } from './RegisterUser';
import MobilePayButton from "../../components/MobilePayButton/MobilePayButton";

/**
 * Normalizes a scandinavian phonenumber with countrycode
 * "+4711223344" -> { countryCode: "+47", number: "11223344" }
 */
const normalizePhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.startsWith('+45') || phoneNumber.startsWith('+46') || phoneNumber.startsWith('+47')) {
    return { countryCode: phoneNumber.slice(0, 3), number: phoneNumber.slice(3) };
  }
  throw new Error('not a valid countrycode phonenumber');
};

/**
 * Step 1 - Enter User details
 * or click register with Vipps
 */
type Props = ENTER_USER_DETAILS & {
  setStep: SetStepFunction;
  authUrlKey: string;
  fromPasswordReset?: FromPasswordReset;
};
type FormValues = UserDetails;
const EnterUserDetails = ({ setStep, authUrlKey, fromPasswordReset }: Props) => {
  const fromPasswordResetPhoneNumber = fromPasswordReset
    ? normalizePhoneNumber(fromPasswordReset.phoneNumber)
    : undefined;

  const { t: translate } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState(
    () => fromPasswordResetPhoneNumber?.countryCode ?? getCountryList()[0].value
  );
  const [errorObj, setErrorObj] = useState({ error: false, errorMessage: '', errorCode: '' });
  const [isSubmitting, setSubmitting] = useState(false);
  const [showVipps, setShowVipps] = useState<boolean>(false);
  const [showMobilePay, setShowMobilePay] = useState<boolean>(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const showVippsParamValue = params.get('showVipps')
        const showVipps = showVippsParamValue === "true" ? true : showVippsParamValue === "false" ? false : window.showVipps;
        setShowVipps(showVipps)

        const showMobilePayParamValue = params.get('showMobilePay')
        const showMobilePay = showMobilePayParamValue === "true" ? true : showMobilePayParamValue === "false" ? false : window.showMobilePay;
        setShowMobilePay(showMobilePay)
  }, []);

  const onSubmit = (rawUserDetails: FormValues) => {
    const phoneNumber = trimValue(selectedCountry + rawUserDetails.phoneNumber);
    const userDetails = { ...rawUserDetails, phoneNumber };

    setSubmitting(true);
    validateUserDetails({ userDetails }).then((response) => {
      if (response.status !== 200) {
        setSubmitting(false);

        // Api might return a list of validation errors instead of one single error message
        // This should be handled better, but for just at least display something
        let errorMessage = response.message;
        if (!errorMessage) {
          if (response.errors?.length > 0) {
            errorMessage = response.errors[0].errorMessage;
          }
        }
        setErrorObj({ error: true, errorMessage, errorCode: response.errorCode });
        return;
      }

      if (fromPasswordReset !== undefined && fromPasswordReset.phoneNumber === userDetails.phoneNumber) {
        // We can skip straight to registration
        registerUserAndLoginAction({
          userDetails,
          verificationCode: fromPasswordReset.verificationCode,
          setStep,
          setErrorObj,
          setSubmitting,
        });
        return;
      }

      createSmsCode({ phoneNumber: userDetails.phoneNumber }).then((response) => {
        if (response.status !== 201 && response.errorCode !== 'recently_sent_try_again_later') {
          setSubmitting(false);
          setErrorObj({ error: true, errorMessage: response.message, errorCode: response.errorCode });
          return;
        }
        setStep({ name: 'VALIDATE_SMS_CODE', userDetails });
      });
    });
  };

  const heading =
    fromPasswordReset === undefined ? translate('registerUser.register') : translate('registerUser.createNewUser');

  const formErrorSummaryRef = useRef<any>(null);
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form
          onSubmit={(e) => {
            handleSubmit(e);
            focusAfterTimeout(formErrorSummaryRef);
          }}>
          <AccessibleH1Heading>{heading}</AccessibleH1Heading>

          <div className="hw-block--mt-medium-2" />

          {showVipps && (
            <div>
              <VippsButton variant="register" authUrlKey={authUrlKey} className="hw-button--large hw-button--full" />

              <div className="hw-block--mt-medium-2" />

                {!showMobilePay && <p className="hw-text-center">{translate('registerUser.orRegisterWithPostenID')}</p>}

              <div className="hw-block--mt-medium-2" />
            </div>
          )}
          {showMobilePay && (
            <div>
              <MobilePayButton variant="register" authUrlKey={authUrlKey} className="hw-button--large hw-button--full" />

              <div className="hw-block--mt-medium-2" />

              <p className="hw-text-center">{translate('registerUser.orRegisterWithPostenID')}</p>

              <div className="hw-block--mt-medium-2" />
            </div>
          )}

          <FormErrorSummary ref={formErrorSummaryRef} />

          <div className="hw-block--mt-medium-2" />

          <Field
            label={translate('registerUser.mobileNumber')}
            name="phoneNumber"
            id="phoneNumber"
            component={PhoneInputType}
            countryOptions={getCountryList()}
            onCountrySelect={setSelectedCountry}
            validate={composeValidators(requiredWithLabel(translate('registerUser.mobileNumber')), validMobileNumber)}
            defaultValue={fromPasswordResetPhoneNumber?.number}
          />
          <div className="hw-block--mb-small-4" />
          <label className="hw-label">
            {translate('registerUser.email')}
            <Field
              type="email"
              component={InputType}
              validate={composeValidators(requiredWithLabel(translate('registerUser.email')), validateEmail)}
              name="emailAddress"
              id="emailAddress"
            />
          </label>
          <div className="hw-block--mb-small-4" />
          <label className="hw-label">
            {translate('registerUser.firstName')}
            <Field
              type="text"
              component={InputType}
              validate={composeValidators(
                requiredWithLabel(translate('registerUser.firstName')),
                firstNameLength(2, 60)
              )}
              name="firstName"
              id="firstName"
            />
          </label>
          <div className="hw-block--mb-small-4" />
          <label className="hw-label">
            {translate('registerUser.lastName')}
            <Field
              type="text"
              component={InputType}
              validate={composeValidators(requiredWithLabel(translate('registerUser.lastName')), lastNameLength(2, 60))}
              name="lastName"
              id="lastName"
            />
          </label>
          <div className="hw-block--mb-small-4" />
          <label className="hw-label" htmlFor="password">
            {translate('registerUser.password')}
          </label>
          <Field
            component={SetPasswordInputField}
            validate={composeValidators(
              requiredWithLabel(translate('registerUser.password')),
              validatePassword(translate)
            )}
            name="password"
            id="password"
          />

          <div className="hw-block--mt-small-4" />

          <Field
            type="checkbox"
            component={CheckboxInputType}
            label={
              <>
                {translate('registerUser.registrationTerms1')}{' '}
                <a target="_blank" href={getTermsLink()} rel="noopener noreferrer" className="hw-link">
                  {translate('registerUser.registrationTerms2')}{' '}
                </a>
                {translate('registerUser.registrationTerms3')}
              </>
            }
            name="userConsent"
            id="userConsent"
            validate={mustAcceptTermsAndConditions}
          />

          <div className="hw-block--mt-small-4" />
          <a target="_blank" href={getConsentLink()} rel="noopener noreferrer" className="hw-link hw-text-small">
            {translate('registerUser.postenIDPrivacy')}
          </a>

          <div className="hw-block--mt-small-4" />
          <ErrorInfo
            error={errorObj.error}
            errorMessage={errorObj.errorMessage}
            phoneNumberInUse={errorObj.errorCode === 'phone_number_in_use'}
          />
          <div className="hw-block--mt-medium-3" />

          <div className="hw-one-whole">
            <button
              type="submit"
              disabled={isSubmitting}
              className="hw-button hw-button--primary hw-button--large hw-button--full">
              {isSubmitting && (
                <span>
                  <i className="fas fa fa-spinner-third fa-spin" role="progressbar" />
                </span>
              )}
              {!isSubmitting && translate('registerUser.createUser')}
            </button>
          </div>

          <div className="hw-block--mt-medium-2" />

          <div className="hw-one-whole hw-text-center">
            <button type="button" className="hw-link" onClick={() => window.history.back()}>
              {translate('registerUser.cancel')}
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default EnterUserDetails;
