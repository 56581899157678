import {useTranslation} from 'react-i18next';

import {loginWithVipps} from '../../../data/api';
import style from './MobilePayButton.module.css';
import {ReactComponent as MobilePayLogo} from './MobilePayLogo.svg';

/**
 * Login/Register with MobilePay
 *
 * Navigates the user to MobilePay for login
 * on successful login the user is navigated back to our MobilePay callback page
 */
interface Props {
  variant: 'register' | 'login';
  authUrlKey: string;
  className?: string;
}
const MobilePayButton = ({ variant, authUrlKey, className = '' }: Props) => {
  const { t: translate } = useTranslation();

  const redirectToMobilePay = () => {
    if (typeof authUrlKey === 'undefined') {
        authUrlKey = ''
    }
    const loginIdentifier = 'MobilePay';
    loginWithVipps({ authUrlKey }, loginIdentifier).then((response) => {
      if (response.status === 200) {
        window.location.assign(response.data);
      }
    });
  };

  let label = '';
  if (variant === 'login') {
    label = translate('login.loginWithMobilePay');
  } else {
    label = translate('register.registerWithMobilePay');
  }

  // Both the login and register text ends with "MobilePay"
  // Since the visual last part is an svg of the word MobilePay we remove it from the translated string
  let labelWithoutMobilePay = label.replace(' MobilePay', '');

  // We hide the text and logo from screen readers with aria-hidden, and add a custom aria-label with the complete label
  // so it's read correctly
  return (
    <button
      aria-label={label}
      type="button"
      className={`hw-button ${style.button} ${className}`}
      onClick={redirectToMobilePay}>
      <span aria-hidden>{labelWithoutMobilePay}&nbsp;</span>
      <MobilePayLogo aria-hidden className={style.mobilepayLogo} />
    </button>
  );
};
export default MobilePayButton;
