import axios from 'axios';
import findContext from '../utils/FindContext';
import config from "../config";

const headers = {
  headers: {
    'Content-Type': 'application/json',
    // @ts-ignore
    'X-CSRF-Token': window.csrfToken,
    'Accept-Language': config.lang,
  },
  method: 'POST',
};

export const sendPhoneNumberForSMSVerification = (data: unknown) =>
  axios(`${findContext()}/api/verifications/phone`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const sendSecondaryNumberForSMSVerification = (secondaryNumber: string) =>
  axios(`${findContext()}/api/profile/secondaryNumber/${secondaryNumber}/verification`, {
    ...headers,
    method: 'POST',
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const verifyAndUpdateNumber = (data: unknown) =>
  axios(`${findContext()}/api/profile/updateMobileNumber`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const validateLogin = (data: unknown) =>
  axios(`${findContext()}/api/session/validate`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const getBankIdUrls = () =>
  axios(`${findContext()}/api/level4/url`, {
    ...headers,
    method: 'GET',
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const getUserProfile = () =>
  axios(`${findContext()}/api/profile`, {
    ...headers,
    method: 'GET',
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const resendVerificationEmail = (data: unknown) =>
  axios(`${findContext()}/api/profile/verifications/email`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const getClientDetails = (data: { authUrlKey: string }) =>
  axios(`${findContext()}/api/oauth/client?authUrlKey=${data.authUrlKey}`, {
    ...headers,
    method: 'GET',
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const acceptTermsAndConditions = () =>
  axios(`${findContext()}/api/profile/terms`, {
    ...headers,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const getDecorator = () =>
  axios(`${findContext()}/api/decorator`, {
    headers: {
      'Content-Type': 'text/html',
      'Accept-Language': config.lang,
    },
    method: 'GET',
  })
    .then((response) => response.data as string)
    .catch((error) => {
      throw error;
    });

export const addSecondaryNumber = (data: unknown) =>
  axios(`${findContext()}/api/profile/secondaryNumber`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const loginWithVipps = (data: unknown, loginIdentifier: string) =>
  axios(`${findContext()}/vipps/api/login?loginIdentifier=${loginIdentifier}`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const vippsMaskedEmailId = (
  uniqueId: string
): Promise<{
  status: 200;
  data: {
    maskedEmailAddress: string;
  };
}> =>
  axios(`${findContext()}/vipps/api/maskedEmailId/${uniqueId}`, {
    ...headers,
    method: 'GET',
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const validateEmailAddressForVipps = (data: {
  uniqueId: string;
  emailAddress: string;
}): Promise<{
  status?: 200;
  message?: string;
}> =>
  axios(`${findContext()}/vipps/api/validateEmail/${data.uniqueId}`, {
    ...headers,
    data: JSON.stringify({ emailAddress: data.emailAddress }),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const vippsDeleteUser = (
  uniqueId: string
): Promise<{
  status: 204;
}> =>
  axios(`${findContext()}/vipps/api/deleteUser/${uniqueId}`, {
    ...headers,
    method: 'DELETE',
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const getVippsInfo = (
  uniqueId: string
): Promise<{
  status: 200;
  data: {
    oauthUrlKey: string;
    phoneNumber: string;
    vippsUserFirstName: string;
    vippsUserLastName: string;
    vippsUserEmailAddress: string;
  };
}> =>
  axios(`${findContext()}/vipps/api/vippsInfo/${uniqueId}`, {
    ...headers,
    method: 'GET',
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const getVippsUserInfo = ({
  uniqueId,
  emailAddress,
}: {
  uniqueId: string;
  emailAddress: string;
}): Promise<{
  status: 200;
  data: {
    oauthUrlKey: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    vippsUserFirstName: string;
    vippsUserLastName: string;
    vippsUserEmailAddress: string;
  };
}> =>
  axios(`${findContext()}/vipps/api/userInfo/${uniqueId}`, {
    ...headers,
    data: JSON.stringify({ emailAddress }),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const updateVippsUserInfo = (data: { isVippsInfoSelected: boolean; uniqueId: string; emailAddress: string }) =>
  axios(`${findContext()}/vipps/api/updateVippsUserInfo`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const registerVippsUser = (uniqueId: string) =>
  axios(`${findContext()}/vipps/api/register/${uniqueId}`, {
    ...headers,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const updateLastLoginAndActivityDate = () =>
  axios(`${findContext()}/api/profile/updateLastLoginAndActivityDate`, {
    ...headers,
    method: 'PUT',
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

/**
 * Password Reset
 */
export const createSmsCodeForPasswordReset = (data: unknown) =>
  axios(`${findContext()}/api/password_resets`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const validateSmsCodeForPasswordReset = (data: unknown) =>
  axios(`${findContext()}/api/password_resets/validate`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const maskedEmailId = (data: unknown) =>
  axios(`${findContext()}/api/password_resets/maskedEmailId`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const validateEmailAddressForPasswordReset = (data: unknown) =>
  axios(`${findContext()}/api/password_resets/validateEmailAndVerificationCode`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const resetPassword = (data: unknown, phoneNumber: string) =>
  axios(`${findContext()}/api/password_resets/${phoneNumber}`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const deleteUserForPasswordReset = (data: unknown) =>
  axios(`${findContext()}/api/password_resets/deleteUserAndAddPhoneNumberAsVerified`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const validatePhoneNumberPasswordAndCreateSmsCode = (data: { phoneNumber: string; password: string }) => {
  return axios(`${findContext()}/api/session/validate-and-create-sms-code`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });
};

export const loginUserWithPasswordAndSmsCode = (data: {
  phoneNumber: string;
  password: string;
  verificationCode: string;
}) => {
  return axios(`${findContext()}/api/session/create-session-with-password-and-sms-code`, {
    ...headers,
    data: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });
};
