import config from '../config';
import {getLinksBasedOnEnv} from './getLinksBasedOnEnv';

export default function findContext() {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'local.posten.no' ||
    window.location.hostname.startsWith('10') ||
    window.location.hostname.startsWith('192.168') ||
    window.location.hostname.startsWith('local.bring.com')
  ) {
    return '/id';
  }
  return '';
}

export const getTermsLink = () => {
  let environment = '';
  ['id.qa.posten.no', 'local.posten.no', 'localhost', 'login.qa.bring.com', 'local.bring.com'].includes(
    window.location.hostname
  )
    ? (environment = 'qa')
    : (environment = 'prod');
  return getLinksBasedOnEnv(config.TERMS_LINK[window.isGreenLogin ? 'bring' : 'posten'][getLanguage(window.isGreenLogin ? 'bring' : 'posten', window.lang)], environment);
};

export const getConsentLink = () => {
  let environment = '';
  ['id.qa.posten.no', 'local.posten.no', 'localhost', 'login.qa.bring.com', 'local.bring.com'].includes(
    window.location.hostname
  )
    ? (environment = 'qa')
    : (environment = 'prod');
  return getLinksBasedOnEnv(config.CONSENT_LINK[window.isGreenLogin ? 'bring' : 'posten'][getLanguage(window.isGreenLogin ? 'bring' : 'posten', window.lang)], environment);
};

const getLanguage = (brand = '', lang = '') => {
  let language = ''
  if (brand == 'bring') {
    if (lang == 'da') {
      language = lang
    } else {
      language = 'sv'
    }
  } else {
    language = 'no'
  }
  return language
}
